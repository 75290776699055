/* eslint-disable @typescript-eslint/no-explicit-any */

import { appContext } from '@lib/store';
// import { createContextStore } from '@lib/utils/contextStore';
import React from 'react';
import createStore, { StoreApi } from 'zustand';
import createContext from 'zustand/context';
import { Category, CategoryTypeId } from '../categories/categories';

import {
  addToDistinctArray,
  isString,
  keys,
  mapObject,
  removeFromDistinctArray,
  values,
} from '@liquorice/allsorts-craftcms-nextjs';
import { useRouter } from 'next/router';
import {
  EntryIndexCategoryType,
  EntryIndexEntryType,
  EntryIndexKey,
  getEntryIndexDefaults,
} from './entryIndexes';
import {
  EntryIndexQueryProps,
  EntryIndexQueryResult,
  getEntryIndexData,
  parseEntryIndexArgs,
} from './getEntryIndexData';
import { EntryIndexQueryVariables } from './_generated/getEntryIndexData.generated';

type Results<T extends EntryIndexKey> = EntryIndexQueryResult<
  EntryIndexEntryType<T>,
  EntryIndexCategoryType<T>
>;

export type EntryIndexStoreState<T extends EntryIndexKey> = Results<T> & {
  isFiltered?: boolean;
  isLoading?: boolean;
  customFetch?: typeof getEntryIndexData;
  setCustomFetch: (fn: typeof getEntryIndexData) => void;
  removeCustomFetch: () => void;
  trigger: (
    props: Partial<EntryIndexQueryProps<EntryIndexEntryType<T>, EntryIndexCategoryType<T>>>,
    customVariables?: EntryIndexQueryVariables
  ) => void;
};

export const createEntryIndexStore = <T extends EntryIndexKey>(
  entryIndex: T,
  initialState?: Maybe<Partial<EntryIndexStoreState<T>>>
) => {
  //
  const defaultArgs = getEntryIndexDefaults(entryIndex) as EntryIndexQueryProps<
    EntryIndexEntryType<T>,
    EntryIndexCategoryType<T>
  >;

  const initArgs = parseEntryIndexArgs({ ...defaultArgs, ...initialState?.args });

  return createStore<EntryIndexStoreState<T>>((set, get) => ({
    entries: [],
    featuredEntry: null,
    categories: [],
    entryCount: 0,
    pages: 1,
    page: 1,
    isFiltered: false,
    isLoading: false,
    ...initialState,
    args: initArgs,
    customVariables: {},
    customFetch: undefined,
    setCustomFetch: (fn) => set({ customFetch: fn }),
    removeCustomFetch: () => set({ customFetch: undefined }),
    trigger: async (newArgs, newCustomVariables) => {
      set({ isLoading: true });

      const {
        args: currentArgs,
        customVariables: currentCustomVariables,
        categories: currentCategories,
        customFetch,
      } = get();

      // ------------------------------------------------------------------------------------------
      // ---- Handle sort ----

      const isNewSort = 'orderBy' in newArgs && newArgs.orderBy !== currentArgs.orderBy;

      // Reset pagination if the sort has changed;
      if (isNewSort) newArgs.page = 1;

      // ------------------------------------------------------------------------------------------
      // ---- Handle search query ----

      const currentQuery = currentArgs.query;
      const isNewQuery = 'query' in newArgs && newArgs.query !== currentQuery;

      // Reset pagination if the query has changed;
      if (isNewQuery) newArgs.page = 1;

      // ------------------------------------------------------------------------------------------
      // ---- Handle categories ----
      const args = { ...currentArgs, ...newArgs };

      const { categoryIds } = args;

      const { mapIds: categoryMapIds } = prepareCategorySelection(currentCategories, categoryIds);

      const entryCategoryQuery = values(categoryMapIds).reduce((results, catIds) => {
        if (catIds?.length) results.push({ id: catIds });
        return results;
      }, [] as { id: ID[] }[]);

      const customVariables = {
        ...currentCustomVariables,
        ...newCustomVariables,
        entryCategoryQuery: entryCategoryQuery.length ? entryCategoryQuery : undefined,
      };

      // ------------------------------------------------------------------------------------------
      set({ args, customVariables });

      const fetchFn = customFetch ?? getEntryIndexData;

      await fetchFn({ ...args, categoryIds: undefined }, customVariables).then((res) => {
        const categories = newArgs.includeCategories ? res.categories : currentCategories;

        const { entries, pages, page, args } = res;

        const isDefaultSort = args.orderBy !== defaultArgs.orderBy;
        const hasCategorySelections = !!categoryIds?.length;
        const isFiltered = !isDefaultSort || hasCategorySelections;

        // console.log(entries.map((v) => ({ score: v.searchScore })));

        set({
          ...res,
          args: { ...args, categoryIds },
          customVariables,
          categories,
          entries,
          pages,
          page,
          isLoading: false,
          isFiltered,
        });
      });
    },
  }));
};

export const compareCategoryArrays = <T extends Category>(oldCats: T[], newCats: T[]) =>
  oldCats.length === newCats.length &&
  oldCats.at(0)?.id === newCats.at(0)?.id &&
  oldCats.at(-1)?.id === newCats.at(-1)?.id;

// ------------------------------------------------------------------------------------------------
// ---- Create the Context ----

export const { Provider: EntryIndexContextProvider, useStore: useEntryIndex } =
  createContext<StoreApi<EntryIndexStoreState<EntryIndexKey>>>();

/**
 * Returns the index data for a given index key.
 *
 * @template T - A generic type parameter that extends EntryIndexKey.
 * @param {T} indexKey - The index key to retrieve the index data for.
 * @returns {IndexData[T] | undefined} - The index data for the given index key, or undefined if it is not available.
 */
export const useIndexData = <T extends EntryIndexKey>(indexKey: T) => {
  const { indexData } = React.useContext(appContext) ?? {};
  return indexData ? indexData[indexKey] : undefined;
};

// ------------------------------------------------------------------------------------------------
// ---- Selectors ----

export const prepareCategorySelection = <C extends CategoryTypeId>(
  categories: { [P in C]?: Category<C>[] },
  categoryIds: ID[] = []
) => {
  const map = mapObject(categories, (cats) => {
    return cats?.filter((v) => categoryIds.includes(v.id));
  });

  const mapIds = mapObject(map, (cats) => cats?.map((v) => v.id));

  const list = keys(map).reduce(
    (result, categoryTypeId) => [...result, ...(map[categoryTypeId] ?? [])],
    [] as Category[]
  );

  const ids = list.map((v) => v.id);

  return {
    list,
    ids,
    map,
    mapIds,
  };
};

export const selectActiveCategories = <State extends EntryIndexStoreState<EntryIndexKey>>(
  state: State
) => {
  const categories = state.categories;
  const categoryIds = state.args.categoryIds ?? [];
  return prepareCategorySelection(categories, categoryIds);
};

// ------------------------------------------------------------------------------------------------
// ---- Hooks ----

export const useEntryIndexCategorySelection = () => {
  const trigger = useEntryIndex((s) => s.trigger);
  const { ids: selection } = useEntryIndex(selectActiveCategories);

  const clearAll = React.useCallback(() => {
    // Bail if nothing to do...
    if (!selection.length) return;

    trigger({ categoryIds: [] });
  }, [selection, trigger]);

  const selectionAdd = React.useCallback(
    (value: ID) => {
      // Bail if nothing to do...
      if (selection.includes(value)) return;

      const newSelection = addToDistinctArray(selection, value);
      trigger({ categoryIds: newSelection });
    },
    [selection, trigger]
  );

  const selectionRemove = React.useCallback(
    (value: ID) => {
      // Bail if nothing to do...
      if (!selection.includes(value)) return;

      const newSelection = removeFromDistinctArray(selection, value);
      trigger({ categoryIds: newSelection });
    },
    [selection, trigger]
  );

  const isSelected = React.useCallback(
    (value: ID) => {
      selection.includes(value);
    },
    [selection]
  );

  return {
    clearAll,
    isSelected,
    selection,
    selectionAdd,
    selectionRemove,
  };
};

/**
 * Retrieve URI query generated args
 */
export const useEntryIndexQueryParams = (): Partial<EntryIndexQueryProps<any, any>> | null => {
  const r = useRouter();
  const categoryIdsRaw = r.query.category || [];
  const categoryIds = isString(categoryIdsRaw) ? categoryIdsRaw.split(',') : categoryIdsRaw;
  if (!categoryIds.length) return null;

  return {
    categoryIds,
  };
};
