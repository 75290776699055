/* eslint-disable @typescript-eslint/no-explicit-any */

import { CategoryTypeId } from '@lib/features/categories/categories';
import { EntrySectionId } from '@lib/features/entries';
import { mapObject } from '@liquorice/allsorts-craftcms-nextjs';
import {
  EntryIndexQueryProps,
  EntryIndexQueryResult,
  getEntryIndexData,
} from './getEntryIndexData';

export const createEntryIndexDefaults = <E extends EntrySectionId, C extends CategoryTypeId>(
  entrySection: E | E[],
  categoryType?: C | C[],
  options?: Partial<EntryIndexQueryProps<E, C>>
): EntryIndexQueryProps<E, C> => ({
  entrySection,
  categoryType,
  ...options,
});

// ------------------------------------------------------------------------------------------------
// ---- Create the config object ----

const entryIndexDefaults = {
  articleIndex: createEntryIndexDefaults('article', 'articleCategory'),
  profileIndex: createEntryIndexDefaults('profile', 'profileCategory'),
  eventIndex: createEntryIndexDefaults(
    'event',
    ['ageGroup', 'eventType', 'eventBrand', 'accessibility'],
    {
      orderBy: 'isEverydayEvent ASC, eventDateStart ASC',
    }
  ),
  eventPerformanceIndex: createEntryIndexDefaults(
    'eventPerformance',
    ['ageGroup', 'eventType', 'eventBrand', 'accessibility'],
    {
      orderBy: 'isEverydayEvent ASC, eventDateStart ASC',
    }
  ),
  learningIndex: createEntryIndexDefaults('learning', ['learningLevel', 'learningCategory'], {
    orderBy: 'title',
  }),
  search: createEntryIndexDefaults(
    [
      'article',
      'event',
      'learning',
      'articleIndex',
      'eventIndex',
      'learningIndex',
      'page',
      'profile',
      'profileIndex',
    ],
    [],
    {
      perPage: 6,
      orderBy: 'score DESC',
    }
  ),
} as const;

// ------------------------------------------------------------------------------------------------
// ---- Extract the Types ----

type EntryIndexSettings = typeof entryIndexDefaults;

// export type EntryIndexKey = Extract<EntryIndexSectionId, keyof EntryIndexSettings>;
export type EntryIndexKey = keyof EntryIndexSettings;

export type EntryIndexEntryType<T extends EntryIndexKey> =
  EntryIndexSettings[T] extends EntryIndexQueryProps<infer E, any> ? E : never;

export type EntryIndexCategoryType<T extends EntryIndexKey> =
  EntryIndexSettings[T] extends EntryIndexQueryProps<any, infer C> ? C : never;

export type AppIndexData = {
  [P in EntryIndexKey]?: EntryIndexQueryResult<EntryIndexEntryType<P>, EntryIndexCategoryType<P>>;
};

export const getEntryIndexDefaults = <T extends EntryIndexKey>(key: T) =>
  entryIndexDefaults[key] as EntryIndexSettings[T];

// ------------------------------------------------------------------------------------------------
// ---- Create the Fetchers ----

export type EntryIndexCallbacks = {
  [P in EntryIndexKey]: (
    props: Partial<EntryIndexSettings[P]>
  ) => Promise<EntryIndexQueryResult<EntryIndexEntryType<P>, EntryIndexCategoryType<P>>>;
};

export const indexDataCallbacks = mapObject(entryIndexDefaults, (defaults) => {
  return async (props?: Partial<typeof defaults>) =>
    await getEntryIndexData({
      ...defaults,
      ...props,
    });
}) as EntryIndexCallbacks;

export const isEntryIndexKey = (x?: string): x is EntryIndexKey => !!x && x in entryIndexDefaults;
export const getIndexDataCallback = <T extends EntryIndexKey>(type: T) => indexDataCallbacks[type];
