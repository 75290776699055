import Box from '@componentPrimitives/Box';
import Flex from '@componentPrimitives/Flex';
import Txt from '@componentPrimitives/Txt';
import CtaButton from '@components/CtaButton';
import IconButton from '@components/IconButton';
import Image from '@components/Image';
import { LightTheme } from '@components/ThemeProvider/LightTheme';
import { useGlobal } from '@lib/store';
import { createToggleStore } from '@lib/utils/createToggleStore';
import { Dialog } from '@mui/material';
import { useBreakpointMin } from '@theme';
import { vars } from '@theme/_vars.css';
import { useEffect } from 'react';
import * as styles from './Popup.css';

const usePopupState = createToggleStore(false, 'popupState');
const usePopupAction = createToggleStore(false, 'popupAction');

const Popup = () => {
  const popup = useGlobal('popUp');
  const { value: popupState, toggle: togglePopup } = usePopupState();
  const { value: popupAction, toggle: toggleAction } = usePopupAction();
  const xl = useBreakpointMin('xl');

  useEffect(() => {
    if (!popupAction && popup?.content && popup.heading && popup.linkCta) {
      const timer = setTimeout(() => {
        togglePopup(true);
      }, 5000);
      return () => clearTimeout(timer); // Cleanup on unmount
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupAction]);

  const handleClose = () => {
    toggleAction(true);
    togglePopup(false);
  };

  if (!popup) return null;

  const { image, heading, content, linkCta } = popup;

  if (!heading && !content && !linkCta) return null;

  return (
    <LightTheme>
      <Dialog
        maxWidth="md"
        open={popupState}
        onClose={handleClose}
        PaperProps={{ sx: { borderRadius: vars.borderRadius.md } }}>
        <Flex
          colorTheme="default"
          colorSet="paper"
          paper
          cx={{ flexDirection: { xs: 'column-reverse', xl: 'row' } }}>
          <Flex spacing="md" justifyContent="center" cx={{ p: 'lg', mY: 'auto' }}>
            {heading && <Txt variant="h4">{heading}</Txt>}
            {content && <Txt html>{content}</Txt>}
            {linkCta && (
              <Box>
                <CtaButton color="action" endIcon={undefined} onClick={handleClose} {...linkCta} />
              </Box>
            )}
          </Flex>
          <Image
            alt=""
            fixedRatio
            transform={xl ? 'squareLgCrop' : 'landscapeLgCrop'}
            className={styles.image}
            {...image}
          />
          <IconButton
            color="paper"
            icon="close"
            onClick={handleClose}
            className={styles.closeIcon}
          />
        </Flex>
      </Dialog>
    </LightTheme>
  );
};

export default Popup;
